import React, { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import InputGroup from '@components/common/InputGroup';
import FormattedNumber from '@components/common/FormattedNumber';
import swapArrow from '@assets/images/swap-arrow.svg';

interface AmountPercentInputProps {
  amount: string | number | null;
  amountChange: (v: string | number | null) => void;
  amountPercent: string | number | null;
  amountPercentChange: (v: string | number | null) => void;
  symbol: string;
  predefined: number[];
  targetValue: string | number;
  targetValueUsd?: string | number;
  withSign?: boolean;
  calcFromTotal?: boolean;
  label: string;
  isInverted?: boolean;
  invertPredefined?: boolean;
  simplePercents?: boolean;
}

const AmountPercentInput = (props: AmountPercentInputProps) => {
  const {
    predefined,
    amount,
    amountChange,
    amountPercent,
    amountPercentChange,
    symbol,
    targetValue,
    targetValueUsd,
    withSign = true,
    calcFromTotal = false,
    isInverted = false,
    invertPredefined = false,
    simplePercents = false,
    label,
  } = props;
  const [amountValue, setAmountValue] = useState<string | null>();
  const [percentValue, setPercentValue] = useState<string | null>();
  const [isPercent, setIsPercent] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const bnAmount = useMemo(() => {
    if (amount === null || amount === undefined) {
      return null;
    }
    return new BigNumber(amount);
  }, [amount]);

  const handleValueChange = (value: string | number | null) => {
    if (value === undefined) {
      setAmountValue((prev) => prev + '.');
      return;
    }
    setAmountValue(value?.toString() || null);
    amountChange(value);
    if (!value) {
      amountPercentChange(null);
      setPercentValue(null);
    } else {
      if (!targetValue || targetValue === '0') {
        amountPercentChange(0);
        setPercentValue('0');
        return;
      }
      let percent = new BigNumber(100)
        .dividedBy(targetValue)
        .multipliedBy(value)
        .minus(calcFromTotal ? 0 : 100);
      if (isInverted && !percent.isNaN()) {
        percent = percent.multipliedBy(-1).plus(100);
      }
      if (percent.isNaN() || percent.isLessThan(0.01)) {
        amountPercentChange(0);
        setPercentValue('0');
      } else {
        console.log(percent.toString());
        let percentString = percent.toFixed(2);
        const hasComma = percentString.includes(',');
        const sign = hasComma ? ',' : '.';
        if (
          percentString.split(sign).length > 1 &&
          percentString.split(sign)[1] == '00'
        ) {
          percentString = percentString.split(sign)[0];
        }
        setPercentValue(percentString);
        amountPercentChange(percentString);
      }
    }
  };

  const handlePercentChange = (
    value: string | number | null,
    predefined = false,
  ) => {
    if (value === undefined) {
      setPercentValue((prev) => prev + '.');
      return;
    }
    setPercentValue(value?.toString() || null);
    amountPercentChange(value);
    if (!value) {
      amountChange(null);
      setAmountValue(null);
    } else {
      let newAmount = new BigNumber(targetValue)
        .multipliedBy((calcFromTotal ? 0 : 100) + parseFloat(value.toString()))
        .dividedBy(100);
      if (isInverted) {
        newAmount = newAmount.multipliedBy(-1).plus(targetValue);
      }
      if (newAmount.isNaN()) {
        newAmount = new BigNumber(0);
      }
      amountChange(newAmount.toFixed());
      setAmountValue(newAmount.toFixed());
    }
  };

  // const handlePercentChange = (value: string | number | null, predefined = false) => {
  //   console.log({predefined, simplePercents});
  //   if (value === undefined) {
  //     setPercentValue((prev) => prev + '.');
  //     return;
  //   }
  //   let bn = new BigNumber(value || 0).plus(simplePercents ? 0 : percentValue || 0);
  //   if (!calcFromTotal && !simplePercents && value && percentValue && bn.minus(percentValue || 0).isGreaterThanOrEqualTo(100)) {
  //     console.log('here');
  //     const multiplier = new BigNumber(value).plus(100).div(100).toNumber();
  //     bn = new BigNumber(percentValue).multipliedBy(multiplier);
  //   }
  //   if (calcFromTotal && bn.isGreaterThanOrEqualTo(100)) {
  //     console.log('here 2');
  //     bn = new BigNumber(99);
  //   }
  //   setPercentValue(value ? bn.toString() : null);
  //   amountPercentChange(value ? bn.toString() : null);
  //   if (!value) {
  //     amountChange(null);
  //     setAmountValue(null);
  //   } else if (predefined) {
  //     let newAmount = new BigNumber(targetValue).multipliedBy((calcFromTotal ? 0 : 100) + parseFloat(bn.toString())).dividedBy(100);
  //     console.log('here 3', newAmount.toString(), bn.toString());
  //     if (isInverted) {
  //       newAmount = newAmount.multipliedBy(-1).plus(targetValue);
  //       console.log('here 4', newAmount);
  //     }
  //     amountChange(newAmount.toFixed());
  //     setAmountValue(newAmount.toFixed());
  //   } else {
  //     console.log('here 5');
  //     setPercentValue(value.toString() || null);
  //     amountPercentChange(value);
  //   }
  // };

  const predefinedChange = (_p: number) => {
    let bn = new BigNumber(_p).abs();
    if (isInverted) {
      bn = bn.multipliedBy(-1).plus(100);
    }
    const percent = bn.toNumber();
    // if (!isPercent) {
    //   const value = new BigNumber(targetValue).multipliedBy(calcFromTotal ? percent : percent + 100).dividedBy(100).toFixed();
    //   handleValueChange(value);
    // } else {
    handlePercentChange(percent, true);
    // }
  };

  const switchMode = () => setIsPercent((prev) => !prev);

  useEffect(() => {
    if (!isInit) {
      handleValueChange(amount?.toString() || null);
      setTimeout(() => {
        setIsInit(true);
      }, 10);
    }
  }, [isInit, amount]);

  const predefinedLabel = (a: number) => {
    if (a < 100)
      return (
        <>
          {withSign && a > 0 ? '+' : ''}
          {a}%
        </>
      );
    if (a >= 100) {
      return <>x{a / 100 + 1}</>
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-start tx-muted mt-3 mb-2 tx-13">
        <div>{label}:</div>
        <span
          className="cur-pointer"
          onClick={() => handleValueChange(targetValue)}
        >
          <FormattedNumber
            value={targetValue}
            decimals={4}
            subZeros
            postfix={symbol === '$' ? undefined : symbol}
            suffix={symbol !== '$' ? undefined : symbol}
            className="tx-white ms-1 text-decoration-underline"
          />
          {!!targetValueUsd && (
            <FormattedNumber
              value={targetValueUsd}
              className="tx-white ms-1"
              suffix="($"
              postfix=")"
              noSpace
              floor
              subZeros
            />
          )}
        </span>
      </div>

      <div className="d-flex justify-content-between align-items-center gap-2">
        {!isPercent && (
          <InputGroup
            value={amountValue || null}
            onChange={handleValueChange}
            placeholder={`0${symbol === '$' ? '$' : ' ' + symbol}`}
            unit={symbol}
            isNumeric
            isInValid={bnAmount ? bnAmount.isLessThanOrEqualTo(0) : false}
            className="ht-50"
          />
        )}
        {isPercent && (
          <InputGroup
            value={amountPercent || null}
            onChange={handlePercentChange}
            placeholder={`0%`}
            unit="%"
            isNumeric
            isInValid={bnAmount ? bnAmount.isLessThanOrEqualTo(0) : false}
            className="ht-50"
          />
        )}
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between gap-2 mt-2">
        <div
          className="card bg-semi-transparent-10 py-1 ht-35 tx-14 justify-content-center tx-muted px-2 wd-100p cur-pointer"
          onClick={switchMode}
        >
          {!isPercent && (
            <FormattedNumber
              value={percentValue || 0}
              postfix="%"
              className="ms-1"
              subZeros
            />
          )}
          {isPercent && (
            <FormattedNumber
              value={amount || 0}
              postfix={symbol}
              className="ms-1"
              subZeros
            />
          )}
        </div>
        <img
          src={swapArrow}
          alt="Swap"
          className="cur-pointer wd-30 mn-wd-30"
          onClick={switchMode}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3">
        {predefined.map((a) => (
          <button
            className="btn btn-semi-10 py-2 px-1 justify-content-center align-items-center wd-100p tx-13"
            onClick={() =>
              predefinedChange(invertPredefined ? (100 + a) * -1 : a)
            }
            key={a}
          >
            {predefinedLabel(a)}
          </button>
        ))}
      </div>
    </>
  );
};

export default AmountPercentInput;
