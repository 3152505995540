import React from 'react';
import CopyButton from '@components/common/CopyButton';
import TokenLogo from '@components/common/TokenLogo';
import copy from 'assets/icons/copy-gray.svg';
import { ChainId } from '../../../constants';
import { WhaleSignalPreviewResponse } from 'types/whale-signals/whale-signals.request';

import { getEllipsisTxt } from '@helpers/formatters';
import { anomalyHourDiff } from '@helpers/anomalyHourDifference';
import { useNavigate } from 'react-router-dom';
import HistoryData from '@components/common/HistoryData';

type WhaleSignalAnomalyProps = WhaleSignalPreviewResponse & {
  className?: string;
};

const WhaleSignalAnomalyItem = (props: WhaleSignalAnomalyProps) => {
  const {
    className,
    token: { address, name },
    anomaly,
    blockchain,
  } = props;

  const navigate = useNavigate();

  const handleTrade = () => {
    navigate(`/limit-orders/${blockchain}/${address}/trade`);
  };

  return (
    <div
      className={`px-0 d-flex flex-row justify-content-between align-items-center wd-100p ${className}`}
    >
      <TokenLogo
        address={address}
        name={name}
        size={45}
        chain={ChainId.ETHER}
      />
      <div className="cur-pointer wd-100p mg-x-12">
        <div
          onClick={handleTrade}
          className="d-flex text-break justify-content-between align-items-center tx-semibold"
        >
          <span>{name}</span>
        </div>

        <div className="d-flex justify-content-between align-items-center tx-13">
          <CopyButton
            text={address}
            className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none me-auto z-index-10"
            iconClass="tx-muted ms-1"
            iconSrc={copy}
          >
            CA: {getEllipsisTxt(address, 4)}
          </CopyButton>
        </div>
      </div>
      <div className="d-flex gap-12px">
        {Object.entries(anomaly)
          .reverse()
          .map(([key, value]) => (
            <div
              key={key}
              className="d-flex flex-column align-items-center m-1"
            >
              <div>
                <HistoryData data={value} />
              </div>
              {key === 'whales' && <span>🐋</span>}
              {key === 'stars' && <span>⭐</span>}
              {key === 'leafs' && <span>🍀</span>}
            </div>
          ))}
      </div>
    </div>
  );
};

export default WhaleSignalAnomalyItem;
