import React from 'react';
import FormattedNumber from '@components/common/FormattedNumber';
import CopyButton from '@components/common/CopyButton';
import { useNavigate } from 'react-router-dom';
import { HotToken } from '../../../types';
import { ChainId } from '../../../constants';
import LabelValueCard from '@components/common/LabelValueCard';
import { formatDistanceToNow } from 'date-fns';
import timeAgo, { getDateFnsLocale } from '@helpers/timeAgo';
import TokenLogo from '@components/common/TokenLogo';
import { useTranslation } from 'react-i18next';
import { getEllipsisTxt } from '@helpers/formatters';
import share from '@assets/icons/share.svg';
import stocks from '@assets/icons/stocks.svg';
import useShareToken from '@hooks/useShareToken';
import { Blockchain } from 'types/enums';
import AlphaLink from '@components/common/AlphaLink';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Badge from '@components/common/Badge';

interface ResearchItemProps {
  data: HotToken & { refId: string | null };
  isGrouped?: boolean;
  isLast?: boolean;
  index?: number;
  noButtons?: boolean;
  chain: ChainId;
  chartLink: string;
  analyticsBot: string;
}

const ResearchItem = ({
  data,
  isGrouped = false,
  isLast = false,
  index,
  noButtons = false,
  chain,
  chartLink,
  analyticsBot,
}: ResearchItemProps) => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [w, AmplitudeWrapper] = useAmplitude();
  const handleShareToken = useShareToken();

  const handleTrade = () => {
    navigate(`/limit-orders/${chain}/${data.pairAddress}/trade`);
  };

  const urlAddress = (
    data.token.address.includes('-')
      ? data.token.address.split('-')[0]
      : data.token.address
  ).toLowerCase();

  return (
    <div
      className={`${isGrouped ? (!isLast ? 'border-semi-transparent border-bottom pb-3' : '') : 'card'} card-blur overflow-hidden mb-3`}
      // style={{
      //   backgroundImage: `url(https://dd.dexscreener.com/ds-data/tokens/${chain}/${urlAddress}.png?size=lg)`,
      // }}
    >
      <div className="d-flex flex-row justify-content-between align-items-center wd-100p z-index-10">
        {data.isNew && (
          <Badge
            variant="custom"
            label="New"
            className="pos-absolute z-index-100 bg-teal tx-black tx-10"
            style={{ transform: `translate(-10px, -25px)` }}
          />
        )}
        <TokenLogo
          address={data.token.address}
          name={data.token.symbol}
          size={45}
          chain={chain}
          useName
        />

        <div className="wd-100p ms-2">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div className="mx-wd-120 lh-2">
              {index !== undefined && (
                <span className="tx-teal me-1">#{index + 1}</span>
              )}
              {data.token.name}
            </div>

            <FormattedNumber
              value={data.price}
              decimals={4}
              suffix="$"
              subZeros
              className="word-break"
              hideNull
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            <CopyButton
              text={data.token.address}
              className="btn btn-link p-0 tx-13 tx-muted tx-left text-decoration-none"
              iconClass="tx-muted ms-1 me-auto"
            >
              CA: {getEllipsisTxt(data.token.address, 4)}
            </CopyButton>
            <FormattedNumber
              value={data.priceChange24h}
              withSign
              className={data.priceChange24h > 0 ? 'tx-success' : 'tx-danger'}
              postfix="%"
              hideNull
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3">
        <LabelValueCard
          label={t('common.liquidity')}
          value={data.liquidity}
          unit="$"
        />
        <LabelValueCard
          label={t('common.volume')}
          value={data.volume}
          unit="$"
        />
        <LabelValueCard
          label={t('common.mcap-shrt')}
          value={data.marketCap}
          unit="$"
        />
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2">
        <LabelValueCard
          label={t('order.age')}
          value={timeAgo(
            new Date(data.createdAt),
            false,
            getDateFnsLocale(language),
          )}
          unit="$"
        />
        <LabelValueCard
          label={t('common.holders')}
          value={data.audit?.holders || t('common.na')}
        />
        <LabelValueCard label={t('common.tax')}>
          {data.audit?.buyTax ? (
            <FormattedNumber
              value={data.audit.buyTax}
              postfix="%"
              className="tx-success"
            />
          ) : (
            <span className="tx-success">{t('common.na')}</span>
          )}
          <span className="tx-muted mx-1">/</span>
          {data.audit?.sellTax ? (
            <FormattedNumber
              value={data.audit.sellTax}
              postfix="%"
              className="tx-danger"
            />
          ) : (
            <span className="tx-danger">{t('common.na')}</span>
          )}
        </LabelValueCard>
      </div>

      {!noButtons && (
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-3 z-index-10">
          <AmplitudeWrapper
            className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
            eventInput={AmplitudeEvent.TOKEN_CHART_TAB_CLICKED}
            eventProperties={{ token: data.pairAddress }}
          >
            <AlphaLink className="d-flex align-items-center" path={chartLink}>
              <img src={stocks} alt="Stocks" width={16} />
            </AlphaLink>
          </AmplitudeWrapper>
          {(chain !== ChainId.SUI && analyticsBot) && (
            <AmplitudeWrapper
              className="d-flex align-items-center btn btn-semi-10 ht-32 py-2 wd-100p"
              eventInput={AmplitudeEvent.TOKEN_ANALYTIC_TAB_CLICKED}
              eventProperties={{ token: data.pairAddress }}
            >
              <AlphaLink
                className="mx-auto d-flex align-items-center tx-13 text-decoration-none text-white "
                path={`https://t.me/${analyticsBot}?start=${data.pairAddress}`}
              >
                {t('common.analytic')}
              </AlphaLink>
            </AmplitudeWrapper>
          )}
          <button
            className="d-flex align-items-center justify-content-center btn ht-32 btn-primary-10 tx-teal py-2 tx-13 wd-100p op-8 ht-40"
            onClick={w(handleTrade, AmplitudeEvent.TOKEN_TRADE_TAB_CLICKED, {
              token: data.pairAddress,
            })}
          >
            {t('common.trade')}
          </button>
          {/*<button*/}
          {/*  className="btn btn-semi-10 py-2 tx-13 bg-semi-transparent d-flex align-items-center justify-content-center"*/}
          {/*>*/}
          {/*  <img src={heart} alt="heart" width={16} height={16} className="me-2"/>*/}
          {/*  {formatNumber(999999, undefined, 0)}*/}
          {/*</button>*/}
          <button
            className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
            onClick={w(
              () =>
                handleShareToken(
                  chain as unknown as Blockchain,
                  data.token.address,
                  data.refId!,
                ),
              AmplitudeEvent.SHARE_BUTTON_CLICKED,
              { token: data.pairAddress },
            )}
          >
            <img src={share} alt="more" width={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ResearchItem;
