import React, {useCallback} from 'react';
import TokenLogo from "@components/common/TokenLogo";
import verified from "@assets/icons/verified.svg";
import FormattedNumber from "@components/common/FormattedNumber";
import {useNavigate} from "react-router-dom";
import {IAsset} from "../../types";
import BigNumber from "bignumber.js";
import tokenNameClass from "@helpers/tokenNameClass";
import AlphaLink from '@components/common/AlphaLink';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import cn from 'classnames';
import { ChainId } from '../../constants';

interface ListItemProps {
  asset: IAsset;
  isInline?: boolean;
  inlineChange?: number;
  hide?: boolean;
  chartLink: string;
}

const ListItem = ({ asset, isInline, inlineChange, hide, chartLink }: ListItemProps) => {
  const navigate = useNavigate();
  const [w] = useAmplitude()
  const {
    assetId,
    value,
    price,
    change1d,
    change1dInPercent,
    assetName,
    quantity,
    assetSymbol,
    assetChain,
    imageUrl,
    flags,
    contractAddress,
  } = asset;

  const handleNavigate = useCallback(() => {
    if (isInline) return;

    navigate(`/asset/${assetId}`);
  }, [isInline, assetId, navigate]);

  const inlineChangeInUsd = new BigNumber(inlineChange || 0).dividedBy(100).multipliedBy(price).toNumber();

  return (
    <div
      className={`${hide ? 'd-none' : 'd-flex'} justify-content-between align-items-center wd-100p pb-3 ${isInline ? '' : 'pt-3 border-muted-10 cur-pointer with-hover'}`}
      onClick={w(handleNavigate, AmplitudeEvent.ASSET_CLICKED_MAIN_SCREEN)}
    >
      <TokenLogo logo={imageUrl} address={assetId} name={assetName} size={45} chain={assetChain} />

      <div className={tokenNameClass(assetName, 'd-flex flex-column align-items-start ms-2 me-auto')}>
        <div className="tx-17 mb-1">
          {assetName}
          {flags.verified && <img src={verified} alt="Verified" className="ms-1" />}
          {isInline && !!contractAddress && !contractAddress.includes('0x000000000') && (
            <AlphaLink path={chartLink} className="tx-16 lh-1 ms-1 text-decoration-none">
              📈
            </AlphaLink>
          )}
        </div>
        <FormattedNumber
          value={quantity}
          postfix={assetSymbol}
          subZeros
          useFormat={new BigNumber(quantity).isGreaterThan(10000)}
          className="tx-13 tx-muted word-break"
        />
      </div>

      <div className="ms-auto me-0 d-flex flex-column align-items-end tx-right">
        <div className="tx-17">
          <FormattedNumber
            suffix="$"
            value={isInline ? price : value}
            decimals={isInline ? 6 : undefined}
            subZeros
            className="word-break"
          />
        </div>
        <div className={cn([
          'tx-13',
          (!isInline ? change1d === null : inlineChangeInUsd === null) ? 'd-none' : '',
          (!isInline ? change1d >= 0 : inlineChangeInUsd >= 0) ? 'tx-success' : 'tx-danger',
        ])}>
          {!isInline ? (
            <>
              <FormattedNumber
                value={change1d}
                postfix="$"
                className={`word-break`}
                withSign={change1d !== 0}
                hideNull
                subZeros
              />
              <span className="mx-1">•</span>
              <FormattedNumber
                value={change1dInPercent}
                postfix="%"
                className={`word-break`}
                hideNull
              />
            </>
          ) : assetChain !== ChainId.SUI && (
            <>
              <FormattedNumber
                value={inlineChangeInUsd}
                postfix="$"
                className={`word-break`}
                withSign={inlineChangeInUsd !== 0}
                subZeros
                hideNull
              />
              <span className="mx-1">•</span>
              <FormattedNumber
                value={inlineChange}
                postfix="%"
                className={`word-break`}
                hideNull
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
};

export default ListItem;
